const Lolz = () => {
  const token = new URLSearchParams(window.location.hash).get('access_token');

  return (
    <div
      style={{
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
      }}
    >
      <div>Нажмите ниже:</div>
      <span
        style={{ textAlign: 'center' }}
        onClick={() => {
          navigator.clipboard.writeText(`/start ${token}`);
          window.location.href = `https://t.me/foxnite_bot?start=lolz`;
        }}
      >
        {`/start ${token}`}
      </span>
    </div>
  );
};

export default Lolz;
