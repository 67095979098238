import { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

const gamePic: { [key: string]: string } = {
  '730':
    'https://ih0.redbubble.net/image.475276620.3559/flat,800x800,075,f.jpg',
  '252490':
    'https://pic.rutubelist.ru/video/52/72/5272d774d117d16cd894f7b26df86ddc.jpg',
  '570':
    'https://yt3.googleusercontent.com/ytc/AMLnZu8_6aZis913Kheo62bMsEfem2axhtulI00H_JDR=s900-c-k-c0x00ffffff-no-rj',
  '578080': 'https://primehack.ru/wp-content/uploads/2020/09/pubg.jpg',
  '252950':
    'https://i.pinimg.com/originals/e6/12/0e/e6120ec7dbba0e74854a5a255f44b301.jpg',
  '440':
    'https://steamuserimages-a.akamaihd.net/ugc/1801997685758532714/25740198B5A879CF1EE660DEC2900209E1888143/?imw=512&amp;imh=512&amp;ima=fit&amp;impolicy=Letterbox&amp;imcolor=%23000000&amp;letterbox=true',
};

const Inventory = () => {
  const { WebApp } = window.Telegram;

  const [searchParams] = useSearchParams();
  const [skins, setSkins] = useState<
    {
      image: string;
      price: number;
      count: number;
      name: string;
      tradable?: boolean;
    }[]
  >([]);

  const [games, setGames] = useState<
    {
      appId: number;
      name: string;
      price: number;
      activity: number;
      skins: {
        image: string;
        price: number;
        count: number;
        name: string;
        tradable?: boolean;
      }[];
    }[]
  >([]);

  useEffect(() => {
    const getGames = async () => {
      const response = await fetch(
        `/steam/${searchParams.get('id')}/inventory`,
        {
          method: 'POST',
        }
      );

      const data: {
        appId: number;
        name: string;
        price: number;
        activity: number;
        skins: {
          image: string;
          price: number;
          count: number;
          name: string;
          tradable?: boolean;
        }[];
      }[] = await response.json();

      const games = data.filter((el) => el?.skins?.length);

      console.log(games[0].skins);

      setGames(games);
      setSkins(
        games[0].skins.sort((a, b) => b.price * b.count - a.price * a.count)
      );
    };

    getGames();
  }, [searchParams, WebApp]);

  return (
    <>
      <div className="header">
        <div className="header__title">Инвентарь:</div>
      </div>
      <div className="games">
        {games.map((game, key) => (
          <img
            key={key}
            src={gamePic[game.appId]}
            alt={game.name}
            style={{
              cursor: 'pointer',
            }}
            onClick={() =>
              setSkins(
                games[key].skins.sort(
                  (a, b) => b.price * b.count - a.price * a.count
                )
              )
            }
          />
        ))}
      </div>
      <div className="content">
        <div className="skins">
          {skins.map((skin, key) => (
            <div key={key} className="skins__item">
              <img
                src={`https://community.cloudflare.steamstatic.com/economy/image/${skin.image}`}
                alt="skin"
              />
              <p
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                {skin.name.length > 30
                  ? skin.name.slice(0, 30) + '...'
                  : skin.name}
              </p>
              <span>
                {skin.price}${skin.count > 1 ? ` * ${skin.count}` : null}
              </span>
              {!skin.tradable ? (
                <span
                  style={{
                    color: 'white',
                    fontSize: '14px',
                    marginTop: '20px',
                    backgroundColor: '#eb3b5a',
                    padding: '10px 20px',
                    borderRadius: '20px',
                  }}
                >
                  Трейд-бан
                </span>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Inventory;
