import { MainButton } from '@vkruglikov/react-telegram-web-app';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';

const EditLink = () => {
  const { WebApp } = window.Telegram;

  const [searchParams] = useSearchParams();
  const [template, setTemplate] = useState<string>('');
  const [redirect, setRedirect] = useState<string>('');

  const setTemplateByFile = async (file: File) => {
    const text = await file.text();

    setTemplate(text);
  };

  const editLink = async () => {
    const response = await fetch('/steam/edit-link', {
      method: 'POST',
      headers: {
        'Tg-Hash': WebApp.initData,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: searchParams.get('id'),
        template,
        redirect,
      }),
    });

    const data = await response.json();

    if (!data.success)
      return WebApp.showAlert(data?.message ? data.message : 'Нет доступа.');

    WebApp.close();
  };

  return (
    <>
      <div className="header">
        <div className="header__title">Изменение шаблона:</div>
      </div>
      <div className="content">
        <form encType="multipart/form-data" className="links">
          <div className="item__ha1">Введите редирект:</div>
          <input
            className="item__input"
            placeholder="https://facecock.com"
            type="text"
            onChange={(e) => setRedirect(e.target.value)}
            style={{
              width: '100%',
            }}
          />
          <div className="item__ha1">Введите шаблон:</div>
          <label className="custom-file-upload">
            <input
              type="file"
              onChange={(e) => setTemplateByFile(e.target.files![0])}
            />
            Загрузить из файла
          </label>
          <textarea
            className="item__textarea"
            placeholder="Шаблон..."
            onChange={(e) => setTemplate(e.target.value)}
            value={template}
          ></textarea>
        </form>
      </div>
      <MainButton
        onClick={() => editLink()}
        text="Изменить ссылку"
        color="#e69d5d"
        textColor="#000000"
      />
    </>
  );
};

export default EditLink;
