import { useCallback, useEffect, useState } from 'react';

import { MainButton } from '@vkruglikov/react-telegram-web-app';
import { useSearchParams } from 'react-router-dom';

interface IConfirmation {
  type: number;
  type_name: string;
  id: string;
  creator_id: string;
  nonce: string;
  creation_time: number;
  cancel: string;
  accept: string;
  icon: string;
  multi: boolean;
  headline: string;
  summary: string[];
  warn: string;
}

const Sda = () => {
  const { WebApp } = window.Telegram;

  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmations, setConfirmations] = useState<IConfirmation[]>([]);
  const [code, setCode] = useState<string>('');

  const pollingConfirmation = useCallback(async () => {
    setIsLoading(true);
    const response = await fetch(
      `/steam/${searchParams.get('id')}/confirmations`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    const confirmations = await response.json();

    if (confirmations.success) setConfirmations(confirmations.conf);
    if (!confirmations.success)
      WebApp.showPopup({ title: 'Error', message: confirmations.message });
    setIsLoading(false);
  }, [searchParams, WebApp]);

  const pollingCode = useCallback(async () => {
    const getCode = async () => {
      const response = await fetch(`/steam/${searchParams.get('id')}/code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      return await response.json();
    };

    const setPolling = async () => {
      const code = await getCode();
      setCode(code.code);
      setTimeout(() => setPolling(), code.time * 1000);
    };

    setPolling();
  }, [searchParams]);

  const confirm = async (confirmationId: string, nonce: string) => {
    const data = confirmations.filter((i) => i.id !== confirmationId);
    setConfirmations(data);

    const response = await fetch(`/steam/${searchParams.get('id')}/confirm`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ confirmationId, nonce }),
    });

    return await response.json();
  };

  const cancel = async (confirmationId: string, nonce: string) => {
    const data = confirmations.filter((i) => i.id !== confirmationId);
    setConfirmations(data);

    const response = await fetch(`/steam/${searchParams.get('id')}/cancel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ confirmationId, nonce }),
    });

    return await response.json();
  };

  useEffect(() => {
    pollingCode();
    pollingConfirmation();

    const qrLogin = async (url: string) => {
      const response = await fetch(
        `/steam/${searchParams.get('id')}/qr-login`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ url }),
        }
      );

      return await response.json();
    };

    WebApp.onEvent('qrTextReceived', async ({ data }: { data: string }) => {
      WebApp.closeScanQrPopup();
      await qrLogin(data);

      WebApp.showPopup({ message: 'Успешно.' });
    });
  }, [WebApp, searchParams, pollingCode, pollingConfirmation]);

  return (
    <>
      <div className="code">
        <div
          className="code__title"
          onClick={() => navigator.clipboard.writeText(code)}
        >
          {code}
        </div>
      </div>
      <div className="header">
        <div className="header__title">Подтверждения:</div>
      </div>
      <div className="content">
        {confirmations?.map((confirmation) => (
          <div className="content__item" key={confirmation?.id}>
            <div>
              <img src={confirmation?.icon} alt="icon" className="item__img" />
              <div className="item__title">{confirmation?.headline}</div>
            </div>
            <div>
              <button
                className="item__confirm"
                onClick={() => confirm(confirmation?.id, confirmation?.nonce)}
              >
                Подтвердить
              </button>
              <button
                className="item__decline"
                onClick={() => cancel(confirmation?.id, confirmation?.nonce)}
              >
                Отклонить
              </button>
            </div>
          </div>
        ))}
      </div>
      <MainButton
        text="Обновить подтверждения"
        progress={isLoading}
        disable={isLoading}
        onClick={() => pollingConfirmation()}
        color="#e69d5d"
        textColor="#000000"
      />
    </>
  );
};

export default Sda;
