import { useCallback, useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

const Stats = () => {
  const { WebApp } = window.Telegram;

  const [stats, setStats] = useState<{
    visits: number;
    authStarts: number;
    authOpens: number;
    logs: number;
  }>({
    visits: 0,
    authOpens: 0,
    authStarts: 0,
    logs: 0,
  });

  const [searchParams] = useSearchParams();

  const getStats = useCallback(async () => {
    const response = await fetch(`/steam/${searchParams.get('id')}/stats`, {
      method: 'POST',
      headers: {
        'Tg-Hash': WebApp.initData,
        'Content-Type': 'application/json',
      },
    });

    const data: {
      visits: number;
      authStarts: number;
      authOpens: number;
      logs: number;
    } = await response.json();

    setStats(data);
  }, [searchParams, WebApp]);

  useEffect(() => {
    getStats();
    setInterval(() => getStats(), 5000);
  }, [WebApp, getStats]);

  return (
    <>
      <div className="header">
        <div className="header__title">Статистика:</div>
      </div>
      <div className="content">
        <div className="stats">
          <div className="stats__item">
            <svg
              width="800px"
              height="800px"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27 9V21H39"
                stroke="#ffffffff"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21 39V27H9"
                stroke="#ffffffff"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M27 21L42 6"
                stroke="#ffffffff"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21 27L6 42"
                stroke="#ffffffff"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span>{stats.visits}</span>
          </div>
          <div className="stats__item">
            <svg
              fill="#ffffffff"
              width="800px"
              height="800px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M19,12v9a2,2,0,0,1-2,2H7a2,2,0,0,1-2-2V3A2,2,0,0,1,7,1h5a1,1,0,0,1,0,2H7V21H17V12a1,1,0,0,1,2,0Zm-7,6a1,1,0,1,0,1,1A1,1,0,0,0,12,18ZM23,4.6V5.4a.4.4,0,0,1-.4.4h-.628a.416.416,0,0,0-.38.269l0,.006a.415.415,0,0,0,.08.458l.444.444a.4.4,0,0,1,0,.571l-.561.561a.4.4,0,0,1-.571,0l-.444-.444a.415.415,0,0,0-.458-.08l-.006,0a.414.414,0,0,0-.269.38V8.6a.4.4,0,0,1-.4.4H18.6a.4.4,0,0,1-.4-.4V7.968a.414.414,0,0,0-.269-.38l-.006,0a.415.415,0,0,0-.458.08l-.444.444a.4.4,0,0,1-.571,0l-.561-.561a.4.4,0,0,1,0-.571l.444-.444a.415.415,0,0,0,.08-.458l0-.006a.416.416,0,0,0-.38-.269H15.4a.4.4,0,0,1-.4-.4V4.6a.4.4,0,0,1,.4-.4h.628a.415.415,0,0,0,.38-.27l0-.005a.415.415,0,0,0-.08-.458l-.444-.444a.4.4,0,0,1,0-.571l.561-.561a.4.4,0,0,1,.571,0l.444.444a.413.413,0,0,0,.458.079l.006,0a.416.416,0,0,0,.269-.38V1.4a.4.4,0,0,1,.4-.4H19.4a.4.4,0,0,1,.4.4v.628a.416.416,0,0,0,.269.38l.006,0a.413.413,0,0,0,.458-.079l.444-.444a.4.4,0,0,1,.571,0l.561.561a.4.4,0,0,1,0,.571l-.444.444a.415.415,0,0,0-.08.458l0,.005a.415.415,0,0,0,.38.27H22.6A.4.4,0,0,1,23,4.6ZM20.2,5A1.2,1.2,0,1,0,19,6.2,1.2,1.2,0,0,0,20.2,5Z" />
            </svg>

            <span>{stats.authOpens}</span>
          </div>
          <div className="stats__item">
            <svg
              width="800px"
              height="800px"
              viewBox="0 0 48 48"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>fingerprint-solid</title>
              <g id="Layer_2" data-name="Layer 2">
                <g id="invisible_box" data-name="invisible box">
                  <rect width="48" height="48" fill="none" />
                </g>
                <g id="icons_Q2" data-name="icons Q2">
                  <g>
                    <path d="M31.7,37.3V21.9a7.7,7.7,0,0,0-15.4,0V37.3a10.7,10.7,0,0,0,4,8.3,1.8,1.8,0,0,0,.9.4,1.4,1.4,0,0,0,1.2-.6,1.5,1.5,0,0,0-.2-2.2,7.4,7.4,0,0,1-2.8-5.9V21.9a4.6,4.6,0,0,1,9.2,0V37.3a1.5,1.5,0,0,1-1.5,1.5,1.6,1.6,0,0,1-1.6-1.5V21.9a1.5,1.5,0,0,0-3,0V37.6a4.6,4.6,0,0,0,4.6,4.3,4.7,4.7,0,0,0,4.6-4.3Z" />
                    <path d="M24,8.1A13.8,13.8,0,0,0,10.2,21.9V37.3a17,17,0,0,0,1.9,7.9,1.6,1.6,0,0,0,1.4.8l.7-.2a1.6,1.6,0,0,0,.6-2.1,14.1,14.1,0,0,1-1.6-6.4V21.9a10.8,10.8,0,0,1,21.6,0V37.3a7.9,7.9,0,0,1-2.9,6,1.4,1.4,0,0,0-.2,2.1,1.4,1.4,0,0,0,1.2.6,1.6,1.6,0,0,0,.9-.3,10.6,10.6,0,0,0,4-8.4V21.9A13.8,13.8,0,0,0,24,8.1Z" />
                    <path d="M24,2A20,20,0,0,0,4,21.9V40.4a1.5,1.5,0,0,0,1.5,1.5,1.6,1.6,0,0,0,1.6-1.5V21.9a16.9,16.9,0,0,1,33.8,0V37.3a12.9,12.9,0,0,1-1.6,6.4,1.5,1.5,0,0,0,.7,2.1l.7.2a1.4,1.4,0,0,0,1.3-.8A16.5,16.5,0,0,0,44,38V21.9A20,20,0,0,0,24,2Z" />
                  </g>
                </g>
              </g>
            </svg>
            <span>{stats.authStarts}</span>
          </div>
          <div className="stats__item">
            <svg
              width="800px"
              height="800px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 19C5 16.7909 6.79086 15 9 15H15C17.2091 15 19 16.7909 19 19C19 20.1046 18.1046 21 17 21H7C5.89543 21 5 20.1046 5 19Z"
                stroke="#ffffffff"
                stroke-width="1"
              />
              <circle
                cx="12"
                cy="7"
                r="4"
                stroke="#ffffffff"
                stroke-width="1"
              />
            </svg>
            <span>{stats.logs}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stats;
