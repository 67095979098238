import { MainButton } from '@vkruglikov/react-telegram-web-app';
import { useCallback, useEffect, useState } from 'react';

const Logs = () => {
  const { WebApp } = window.Telegram;

  const [logs, setLogs] = useState<string>('');

  const getLogs = useCallback(async () => {
    const response = await fetch('/steam/logs', {
      method: 'POST',
      headers: {
        'Tg-Hash': WebApp.initData,
        'Content-Type': 'application/json',
      },
    });

    const data = await response.text();

    setLogs(data.split('\n').reverse().join('\n'));
  }, [WebApp]);

  useEffect(() => {
    getLogs();
    setInterval(() => getLogs(), 5000);
  }, [WebApp, getLogs]);

  return (
    <>
      <div className="header">
        <div className="header__title">Логи:</div>
      </div>
      <div className="content">
        <div className="links">
          <textarea
            className="item__textarea"
            placeholder="Пусто..."
            disabled
            value={logs}
          ></textarea>
        </div>
      </div>
      <MainButton
        onClick={() => getLogs()}
        text="Обновить логи"
        color="#e69d5d"
        textColor="#000000"
      />
    </>
  );
};

export default Logs;
